/**
* Project : simply-countdown
* File : simplyCountdown.theme.losange
* Date : 27/06/2015
* Author : Vincent Loy <vincent.loy1@gmail.com>
*/
.simply-countdown-losange {
  overflow: visible;
  display: table;
  font-family: 'Arial', sans-serif;
}
.simply-countdown-losange > .simply-section {
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  float: left;
  margin: 30px;
  background: #024772;
}
.simply-countdown-losange > .simply-section > div {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.simply-countdown-losange > .simply-section > div .simply-amount,
.simply-countdown-losange > .simply-section > div .simply-word {
  display: block;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
